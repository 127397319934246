import React from 'react';
import './styles.scss';

interface ErrorProps {
    type: '404' | '403';
    infoPage: string;
}

export const ErrorComponent: React.FC<ErrorProps> = (props: ErrorProps) => {
    return (
        <div className='error__container'>
            {props.type === '403' ? <img src="/assets/icons/403.svg" alt="403" width={500} height={200} /> : <img src="/assets/icons/404.svg" alt="404" width={500} height={200}/>}
            <img src="/assets/icons/loadingBar.svg" alt="loadingErrorBar" height={150} width={400} />
            <div>{props.infoPage}</div>
        </div>);
};