import { selectMainState } from "../../stateManagement/redux"
import ButtonCircle from '../../components/button_circle';
import { Files } from "./interfaces"
import ButtonBig from "../../components/button_big"
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ActiveUser } from '../../stateManagement/redux/slices/main/interfaces';
import { useNavigate, useLocation } from 'react-router-dom';

export const MemberRow = ({ user, buttonLabel, onClick }: { user: ActiveUser, buttonLabel: string, onClick: any }) => {
    return (
        <div className='Upload_files_container_member_list_container_item_user' >
            <div>{user.name} </div>
            < div > {user.email} </div>
            < div > {user.role} </div>
            < ButtonBig text={buttonLabel} onClick={() => onClick()} />
        </div>
    )
}

export const FileRow = ({ file, onRemove }: { file: Files, onRemove: any }) => {


    const getItem = (type: string) => {
        if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return <img src='/assets/icons/excel_icon.svg' width={30} height={30} alt='' />
        } else if (type === 'application/pdf') {
            return <img src='/assets/icons/pdf.svg' width={30} height={30} alt='' />
        } else if (type === 'image/png') {
            return <img src='/assets/icons/image_icon.svg' width={30} height={30} alt='' />
        } else {
            return <img src='/assets/icons/other_file.svg' width={30} height={30} alt='' />
        }

    }

    return (
        <div className='Upload_files_container_member_list_container_item_file' >
            <div>{getItem(file.fileType)} </div>
            < div > {file.fileName} </div>
            < div > {file.fileSize} </div>
            < div > {file.status} </div>
            < ButtonBig text='Remover' onClick={() => onRemove(file)} />
        </div>
    )
}

export const UploadModal = ({ close }: { close: any }) => {

    const navigate = useNavigate();

    const mainState = useSelector(selectMainState);

    const [icon, setIcon] = useState('/assets/gifs/uploading.gif')

    const [showClose, setShowClose] = useState(false)


    useEffect(() => {

        switch (mainState?.uploadingStep) {
            case 'Error Uploading':
                setIcon('/assets/icons/error.svg')
                setShowClose(true)
                break;

            case 'Task Uploaded':
                setIcon('/assets/icons/ok.svg')
                setShowClose(true)
                break;

            default:
                setIcon('/assets/gifs/uploading.gif')
                setShowClose(false)
                break;
        }

    }, [mainState])



    return (
        <div className='Upload_files_container_upload_modal_container' >
            <div className='Upload_files_container_upload_modal_container_input_container' >
                <img src={icon} width={130} height={130} alt='' />
                <div>{mainState?.uploadingStep} </div>
                {
                    showClose &&
                    <div className='Upload_files_container_upload_modal_container_button_container' >
                        {mainState?.uploadingStep !== 'Task Uploaded' && <ButtonBig text='Cerrar' onClick={close} />}
                        <ButtonBig text='ir al historial' onClick={() => navigate('/well-whisper/history')} />

                    </div>
                }
            </div>
        </div>
    )
}

export const ModalUser = (
    {selectedUsers,setSelectedUsers,setModalUser,enabledUsers}
    :{selectedUsers:ActiveUser[],setSelectedUsers:any,setModalUser:any,enabledUsers:ActiveUser[]}
) => {

    const [filterText, setFilterText] = useState('')

    const [filterUser, setFilterUser] = useState<ActiveUser[]>([])

    const selectUser = (user: ActiveUser) => {

        const wasAddedBefore = selectedUsers.find(u => u.email === user.email);

        if (!wasAddedBefore) {
            setSelectedUsers((prevUsers: any) => [...prevUsers, user]);
        }
    }

    useEffect(() => {

        if (filterText === '') {
            setFilterUser(enabledUsers)
        } else {
            setFilterUser(enabledUsers.filter(user => user.name.toLowerCase().includes(filterText.toLowerCase())))
        }

    }, [filterText])


    return (
        <div className='Upload_files_container_modal_user_container'>
            <div className='Upload_files_container_modal_user_container_form_container'>
                <div className='Upload_files_container_modal_user_container_header'>
                    <ButtonCircle icon="/assets/icons/close.svg" iconHeight={20} iconWidth={20} onClick={() => setModalUser(false)} />
                </div>
                <input className='Upload_files_container_modal_user_container_input'
                    type='email'
                    placeholder='Nombre de Usuario'
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
                <div className='Upload_files_container_member_list_container'>
                    {filterUser.map((user, index) =>
                        <MemberRow key={index} user={user} buttonLabel='Agregar' onClick={() => selectUser(user)} />)
                    }
                </div>
            </div>
        </div>
    )
}