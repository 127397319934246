import { Header } from '../header'
import { RightMenuProjects } from '../right_menu_projects'
import ButtonBig from '../../components/button_big'
import {
    useDispatch,
    useSelector,
    mainSlice,
    selectMainState,
} from "../../stateManagement/redux";

import './style.scss'
import { useEffect, useState } from 'react';
import { getDepartmentInfo, login, validateToken } from '../../stateManagement/redux/slices/main/asyncReducers';
import { useNavigate, useLocation } from 'react-router-dom';
import { DepartmentNameEnum } from '../../stateManagement/redux/slices/main/interfaces';

export const WrapMain = ({ children }: { children: any }) => {

    const mainState = useSelector(selectMainState);

    useEffect(() => {

        if (!mainState.activeUser){
            dispatch(validateToken())
        }

        if (localStorage.getItem('activeDepartmentId') && !mainState?.activeDepartment) {
            dispatch(mainSlice.actions.setDepartmentId(localStorage.getItem('activeDepartmentId') as DepartmentNameEnum))
            dispatch(getDepartmentInfo(localStorage.getItem('activeDepartmentId') as string))
        }

        if (localStorage.getItem('activeProjectId')) {
            dispatch(mainSlice.actions.setActiveProjectId(localStorage.getItem('activeProjectId') as string))
        }

    }, [])


    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();

    const onClickMenu = (path: string) => {
        dispatch(mainSlice.actions.setShowMenu())
        navigate(path)
    }

    if (mainState.activeUser) {
        return (
            <>
                {mainState.loading && <Loading />}
                <div className='wrap-main'>
                    <Header />
                    <div className='wrap-main__content'>
                        <RightMenuProjects />
                        {(mainState?.showMenu == true && mainState?.activeDepartmentId === DepartmentNameEnum.wellWhisper) && <WellWhisperOptions onClick={onClickMenu} />}
                        {(mainState?.showMenu == true && mainState?.activeDepartmentId === DepartmentNameEnum.rig) && <RigOptions onClick={onClickMenu} />}
                        {(mainState?.showMenu == true && mainState?.activeDepartmentId === DepartmentNameEnum.tanks) && <TanksOptions onClick={onClickMenu} />}
                        <div className='wrap-main__content_body'>
                            {children}
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <Login />
        )
    }


}

const Loading = () => {
    return (
        <div className='wrap-main__loading'>
            <div className='wrap-main__loading_gif'>
                <img src="/assets/gifs/loading.gif" alt='Loading' height={400} width={400} />
            </div>
        </div>
    )
}

const Login = () => {

    const dispatch = useDispatch();

    const mainState = useSelector(selectMainState);

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const onLogin = () => {
        dispatch(login(email, password))
    }


    return (
        <>
            {mainState.loading && <Loading />}
            <div className='wrap-main_login'>
                <div className='wrap-main_login_logo'>
                    <img src="/assets/icons/logo_without_text.svg" alt='Well Whisper' height={90} width={90} />
                    <img src="/assets/icons/logo_text.svg" alt='Well Whisper' height={30} width={190} />
                </div>
                <div className='wrap-main_login_form'>
                    <div className='wrap-main_login_form_title'>Login</div>
                    <input className='wrap-main_login__input' type='email' placeholder='Search' value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input className='wrap-main_login__input' type="password" placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                    <ButtonBig text='Iniciar Sesion' onClick={onLogin} />
                </div>
            </div>
        </>
    )
}

const WellWhisperOptions = (props: { onClick: any }) => {


    const mainState = useSelector(selectMainState);

    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(mainSlice.actions.setShowMenu())
    }

    const onClickMenu = (path: string) => {
        if (!mainState.activeDepartment || mainState?.activeDepartment?.id !== DepartmentNameEnum.wellWhisper ) {
            localStorage.setItem('activeDepartmentId', DepartmentNameEnum.wellWhisper)
            dispatch(mainSlice.actions.setShowMenu())
            dispatch(getDepartmentInfo(DepartmentNameEnum.wellWhisper))
        }
        props.onClick(path)
    }

    return (
        <div className='wrap-main__menu'>
            <div className='wrap-main__menu__container_header'>
                <img src="/assets/icons/well_whisper.svg" alt='Well Whisper' height={40} width={40} />
                <div>Well Whisper</div>
                <div className='wrap-main__menu__close_icon' onClick={() => onClick()}>
                    <img src="/assets/icons/arrow-left.svg" alt='Well Whisper' height={8} width={8} />
                </div>
            </div>
            <div className='wrap-main__menu__container_buttons'>
                <ButtonBig text='Dashboard' onClick={() => onClickMenu('/well-whisper/dashboard')} />
                <ButtonBig text='Ali' onClick={() => onClickMenu('/well-whisper/ali')} />
                <ButtonBig text='Historial Actividades' onClick={() => onClickMenu('/well-whisper/history')} />
                <ButtonBig text='Subidas de Archivos' onClick={() => onClickMenu('/well-whisper/upload')} />
            </div>
        </div>
    )
}

const RigOptions = (props: { onClick: any }) => {

    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(mainSlice.actions.setProjectOnClick())
    }

    return (
        <div className='wrap-main__menu'>
            <div className='wrap-main__menu__container_header'>
                <img src="/assets/icons/rigs.svg" alt='Well Whisper' height={40} width={40} />
                <div>Operaciones de Taladro</div>
                <div className='wrap-main__menu__close_icon' onClick={() => onClick()}>
                    <img src="/assets/icons/arrow-left.svg" alt='Well Whisper' height={8} width={8} />
                </div>
            </div>
            <div className='wrap-main__menu__container_buttons'>
                {/* <ButtonBig icon={Dashboard} text='Dashboard' iconHeight={30} />
                <ButtonBig icon={Chat} text='Ali' iconHeight={30} />
                <ButtonBig icon={History} text='Historial Actividades' iconHeight={30} />
                <ButtonBig icon={Upload} text='Subidas de Archivos' iconHeight={30} /> */}
            </div>
        </div>
    )
}

const TanksOptions = (props: { onClick: any }) => {

    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(mainSlice.actions.setProjectOnClick())
    }

    return (
        <div className='wrap-main__menu'>
            <div className='wrap-main__menu__container_header'>
                <img src="/assets/icons/tanks.svg" alt='Well Whisper' height={40} width={40} />
                <div>Patio de Tanque</div>
                <div className='wrap-main__menu__close_icon' onClick={() => onClick()}>
                    <img src="/assets/icons/arrow-left.svg" alt='Well Whisper' height={8} width={8} />
                </div>
            </div>
            <div className='wrap-main__menu__container_buttons'>
                {/* <ButtonBig icon={Dashboard} text='Dashboard' iconHeight={30} />
                <ButtonBig icon={Chat} text='Ali' iconHeight={30} />
                <ButtonBig icon={History} text='Historial Actividades' iconHeight={30} />
                <ButtonBig icon={Upload} text='Subidas de Archivos' iconHeight={30} /> */}
            </div>
        </div>
    )
}

