import React from 'react'

import './styles.scss'

interface Props {
  icon: any;
  onClick?: () => void
  iconHeight?: number;
  iconWidth?: number;
  notification?: boolean;
}

export default function Button_circle(props: Props) {
  return (
    <div className='button_circle' onClick={props.onClick}>
      <img src={props.icon} alt='logo' height={props.iconHeight || 50} width={props.iconWidth || 50} />
      {props.notification && <div className='button_circle__alert'/>}
    </div>
  )
}
