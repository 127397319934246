"use client"; 

import { WrapMain } from '../../../../modules/wrap_main'
import '../../../../styles/globalstyles.scss';


const index = () => {
    return (
      <WrapMain>
        <div>Lago</div>
      </WrapMain>
    )
  }
  
  export default index