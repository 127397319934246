import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import {reducers} from './syncReducers'
import { mainSliceState } from "./interfaces";



const initialState: mainSliceState = {
  activeDepartmentId: undefined,
  showMenu: false,
  notification: [],
  activeUser: undefined,
  loading: false,
  showUserMenu: false,
  activeDepartment:undefined,
  usersList: [],
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers,
});