import ButtonCircle from '../../components/button_circle';
import './styles.scss';
import { selectMainState,  useDispatch } from '../../stateManagement/redux';
import ButtonBig from '../../components/button_big'
import { useDropzone } from 'react-dropzone'
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createTaskWithFiles, getUsersList } from '../../stateManagement/redux/slices/main/asyncReducers';
import { Files, UploadFileProps } from './interfaces';
import { FileRow, MemberRow, ModalUser, UploadModal } from './auxiliar-component';
import { ActiveUser, Backlog, Sprint } from '../../stateManagement/redux/slices/main/interfaces';
import { useNavigate, useLocation } from 'react-router-dom';


export const UploadFileModule = (props: UploadFileProps) => {

    const typesOfActivities = ['ECHOMETER', 'LEUTER', 'REVISION']

    const mainState = useSelector(selectMainState);

    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();

    const [activesBacklogs, setActiveBacklogs] = useState<Backlog[]>([])

    const [selectedBacklog, setSelectedBacklog] = useState('')

    const [sprintList, setSprintList] = useState<Sprint[]>([])

    const [modalUser, setModalUser] = useState(false)

    const [files, setFiles] = useState<Files[]>([])

    const [selectedUsers, setSelectedUsers] = useState<ActiveUser[]>([])

    const [enabledUsers, setEnabledUsers] = useState<ActiveUser[]>([])

    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const [showUploadModal, setShowUploadModal] = useState(false)

    const [typeOfActivity, setTypeOfActivity] = useState('')

    const [sprint, setSprint] = useState('')

    useEffect(() => {
        dispatch(getUsersList())
    }, [])

    useEffect(() => {

        if (mainState.activeDepartment && mainState.activeProjectId) {
            const project = mainState.activeDepartment?.projects?.find(p => p.id === mainState.activeProjectId)
            setActiveBacklogs(project?.backlogs || [])
        }

        if (mainState.usersList.length > 0) {
            setEnabledUsers(mainState.usersList)
        }

    }, [mainState])

    useEffect(() => {
        const sprints = activesBacklogs.find(backlog => backlog.id === selectedBacklog)?.sprints || []
        setSprintList(sprints)
    }, [selectedBacklog])


    const onDrop = useCallback((acceptedFiles: File[]) => {
        setUploadedFiles(prevFiles => [...prevFiles, ...acceptedFiles]);

        acceptedFiles.map(file => {
            setFiles(prevFiles => [...prevFiles, {
                fileName: file.name,
                fileType: file.type,
                fileSize: `${(file.size / 1024).toFixed(2)} KB`,
                status: 'No Cargado',
                file: file
            }])
        })

    }, []);

    const startUploadFiles = () => {
        setShowUploadModal(true)
        dispatch(createTaskWithFiles({
            sprintId: sprint,
            teamMembersIds: selectedUsers?.map(x => x.id),
            totalFiles: uploadedFiles.length,
            type: typeOfActivity,
            close: undefined,
            selectedFiles: files
        }))
    }


    const removeFile = (file: Files) => {
        setFiles(prevFiles => prevFiles.filter(f => f !== file));
    }

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick: true });

    const removeUser = (user: ActiveUser) => {
        setSelectedUsers(prevUsers => prevUsers.filter(u => u !== user));
    }


    return (
        <div className='Upload_files_container'>
            {showUploadModal &&
                <UploadModal close={() => setShowUploadModal(false)} />}
            {/* Headers */}
            <div className='Upload_files_container_header'>
                <ButtonCircle icon="/assets/icons/back.svg" iconHeight={50} iconWidth={50} onClick={() => navigate('/well-whisper/upload')} />
                <div className='Upload_files_container_title'>{props.title}</div>
            </div>
            {/* Body */}
            <div className="Upload_files_container_body">
                {/* Instrucciones */}
                <div className='Upload_files_container_instructions_section' >
                    <div className='Upload_files_container_instructions_section_title'>{props.title}</div>
                    <ul className='Upload_files_container_instructions_section_instructions_list'>
                        {props.templateUrl && (
                            <li className='Upload_files_container_instructions_section_instructions_list_download'>
                                <a href={props.templateUrl} download>Descargar plantilla activa para la actividad</a>
                            </li>
                        )}
                        {props.instructions?.map((instruction, index) => (
                            <li key={index} className='Upload_files_container_instructions_section_instructions_list_item'>
                                {instruction}
                            </li>
                        ))}
                    </ul>
                    {props.alertInstructions && (
                        <div className='Upload_files_container_instructions_section_detail'>
                            <img src="/assets/icons/info.svg" width={20} height={20} alt='' />
                            {props.alertInstructions}
                        </div>
                    )}
                </div>
                {/* Formulario */}
                <div className='Upload_files_container_form_section'>
                    <div className='Upload_files_container_form_title'>Campos Requeridos</div>
                    {/* Listas Iniciales */}
                    <>

                        <div className='Upload_files_container_custom_input'>
                            <label htmlFor="file">Actividad : </label>
                            <select id="activity" name="activity" className='Upload_files_container_form_section_select' value={selectedBacklog} onChange={(e) => setSelectedBacklog(e.target.value)}>
                                <option value=''>Selecciona una actividad</option>
                                {activesBacklogs.map((backlog, index) => <option key={index} value={backlog.id}>{backlog.name}</option>)}
                            </select>
                        </div>

                        <div className='Upload_files_container_custom_input'>
                            <label htmlFor="file">Periodo : </label>
                            <select id="activity" name="activity" className='Upload_files_container_form_section_select' value={sprint} onChange={(e) => setSprint(e.target.value)}>
                                <option value=''>Seleccione opcion valida</option>
                                {sprintList.map((backlog, index) => <option key={index} value={backlog.id}>{backlog.name}</option>)}
                            </select>
                        </div>

                        <div className='Upload_files_container_custom_input'>
                            <label htmlFor="file">Tipo Actividad : </label>
                            <select id="activity" name="activity" className='Upload_files_container_form_section_select' value={typeOfActivity} onChange={(e) => setTypeOfActivity(e.target.value)}>
                                <option value=''>Seleccione opcion valida</option>
                                {typesOfActivities.map((value, index) => <option key={index} value={value}>{value}</option>)}
                            </select>
                        </div>
                    </>
                    {/* User Container */}
                    <>
                        <div className='Upload_files_container_custom_input'>
                            <label htmlFor="file">Compañeros de cuadrilla :</label>
                            <ButtonCircle icon="/assets/icons/add_user.svg" iconHeight={20} iconWidth={20} onClick={() => setModalUser(true)} />
                        </div>
                        <div className='Upload_files_container_member_list_container'>
                            {modalUser && <ModalUser
                                selectedUsers={selectedUsers}
                                setSelectedUsers={setSelectedUsers}
                                setModalUser={setModalUser}
                                enabledUsers={enabledUsers} />}
                            {selectedUsers.map((user, index) => <MemberRow key={index} user={user} buttonLabel='Remover' onClick={() => removeUser(user)} />)}
                        </div>
                    </>
                    {/* files container */}
                    <>
                        <div className='Upload_files_container_custom_input'>
                            <label htmlFor="file">Archivos :</label>
                            <ButtonCircle icon="/assets/icons/attach_file.svg" iconHeight={20} iconWidth={20} onClick={open} />
                        </div>
                        <div className='Upload_files_container_member_list_container' {...getRootProps()}>
                            <input {...getInputProps()} />
                            {files.length > 0
                                ? files.map((file, index) => <FileRow key={index} file={file} onRemove={removeFile} />)
                                : <div className='Upload_files_container_nofiles_section'>
                                    {!isDragActive &&
                                        <>
                                            <img src='/assets/icons/upload_icon.svg' width={70} height={70} alt='' />
                                            <div>Arrastra o suelta aquí el archivo para subirlo o selecciona desde tus archivos</div>
                                            <ButtonBig text='Cargar Archivos' onClick={open} />
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    </>
                    {/* save button */}
                    <div className='Upload_files_container_save_button'>
                        <ButtonBig text='Cargar Archivos' icon='/assets/icons/upload_file.svg' onClick={() => startUploadFiles()} />
                    </div>
                </div>
            </div>
        </div>
    )
}