import React from 'react'

import './styles.scss'

interface Props {
  icon?: any;
  text: string;
  onClick?: () => void
  iconHeight?: number;
  iconWidth?: number;
  notification?: boolean;
}

export default function ButtonBig(props: Props) {
  return (
    <div className='button_big' onClick={props.onClick}>
      {props.icon && (
        <img src={props.icon} alt='logo' height={props.iconHeight || 30} width={props.iconWidth || 30} />
      )}
      <div>{props.text}</div>
      {props.notification && <div className='button_big__alert' />}
    </div>
  )
}
