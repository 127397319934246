"use client"; 

import { WrapMain } from '../../../../modules/wrap_main'
import '../../../../styles/globalstyles.scss';
import { UploadFileModule } from '../../../../modules/upload_files'

const index = () => {
  return (
    <WrapMain>
      <UploadFileModule 
        title={'Instrucciones para cargar Archivos'} 
        instructions={[
          'Cargar las actividades del dia en formato excel',
          'Cargar el archivo .TAM del analizador','Cargar las cartas de los distintos estudios en formato .txt',
          'Cargar cualquier otro archivo relacionado como fotos, odt u otros',
          'Inicialmente debes escoger la zona donde realizaste la actividad y agregar a todos los compañeros que han estado en la cuadrilla de trabajo' ]} 
        templateUrl='https://alifiles.s3.us-east-1.amazonaws.com/Cambio%20familias%20categorias.xlsx?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECEaCXVzLWVhc3QtMSJFMEMCHxEVUpUlFPJfGLPPwHqtAzqit0%2B8mDlB%2BpxY%2BDmAad4CIBp52sd3qrPfO3FKMPDXKrHy3fiSRDdpPke4glSsaYwmKugCCEoQAhoMNjEwODA3ODMyNjczIgys%2FbEghhsVuoiEN2YqxQIVRUxF1Jb2PbRr9%2FO3GG7bY2B8kucvanCoF5Q4NNXUlkvx128reKcOAqI%2Fp5afEHelOZHzhWIiu7OiHmnyXGZTCswSns7LNXMIDahOPk9Iv7rCvyUxgz%2F3Helss5ipaUb0rRdHFkX3xIDZVlbtRPOaNa5vGoO0uNr7CbtOtwDAJFhTg0Syq6eF3MzsPzADiMymZ%2BA9jIsbCQXZRu9YbH5UrUbNBjUUqMwTLsUiMx86f3xCHrMQFgxdkSEwZUg5n9OPm3o%2FyZZB4e5jUlze%2F4sSJ%2FDwyrDxZEHOS3ZU0C0rPZJbuGMwXxEsJyIZiPFlIBoWc766fUQkEIhDOms7AI15J4hYbRGzRrK8DyzacY3Bd6Eh5dE2%2BcLvyTMO%2F6wLooJOCgEb2K7dtXJZL3GIqCrBnxXIj%2B30Ic%2FaoFc7Ay75vcGGbrxvMPuv97YGOrUCxzywSIB6fJDjo4ZoxOLbjRzceJ%2BHXyD9eB1uOgZ0VL2zD166mH34QR7yPxdWFakNfhb5a5yOVYQt%2FThea1j%2FFrPhFDoMMVRnp3sJBHaHG5q7aj5wG2AiaNcqphSuFyM8kvbmdjYKhvSp2%2B61GB32LcCHBEZKMDECiPyVHAoEPcPOen9zktSV9LY1ayx8BG3MUQJiuVhsw49uQzDoGivNdrLYCEoN3Yhs%2BeAuUkYG9yZNH6f7mI1M05G4lq4K%2F0qEq6RZXCJguZL8jMaLApckP6E51jBnmOhM7D9r4Qve%2FWZ6tK9sg2yBOk1oV7I4xKdJYRoGh35bJrGOSkH26gwOCbbHTYADhRdfJXyBYRPZYnLcTD9MnZVgqctatbUswJm5dUl3YYHZegKUxwrsiiPGznNO6bV3&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240908T170116Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAY4NX34BQXDQXZBZ5%2F20240908%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=ea8d41e78f32cb877ed90782995e8b12287fe083abc5ed27a6d8ca56184af15f'
        alertInstructions='Si ya ha cargado las actividades de la cuadrilla no vuelva a cargar los archivos puede editar la actividad anterior si esta no se ha iniciado a editar'
        />
    </WrapMain>
  )
}

export default index