"use client"; 

import React from 'react'
import {WrapMain} from '../../../modules/wrap_main'
import { HistoryTaskModule } from '../../../modules/history-task';

import '../../../styles/globalstyles.scss';



const index = () => {
  return (
    <WrapMain>
      <HistoryTaskModule />
    </WrapMain>
  )
}

export default index