import { PayloadAction } from "@reduxjs/toolkit";
import { login } from "./asyncReducers";
import { ActiveDepartment, ActiveUser, DepartmentNameEnum, mainSliceState } from "./interfaces";

export const reducers = {
    setProjectOnClick: (state: mainSliceState, action: PayloadAction<DepartmentNameEnum|undefined>) => {
        state.activeDepartmentId = action.payload;
        state.showMenu = true;
    },
    setDepartmentId: (state: mainSliceState, action: PayloadAction<DepartmentNameEnum|undefined>) => {
        state.activeDepartmentId = action.payload;
        state.showMenu = false;
    },
    setShowMenu: (state: mainSliceState) => {
        state.showMenu = !state.showMenu;
    },
    setActiveProjectId: (state: mainSliceState, action: PayloadAction<string>) => {
        state.activeProjectId = action.payload;
    }, 
    updateState :(state:mainSliceState, action: PayloadAction<any>) => {
        state.loading = action.payload.status;
    },
    activeUser :(state:mainSliceState, action: PayloadAction<ActiveUser | undefined>) => {
        state.activeUser = action.payload;
    },
    openCloseUserMenu :(state:mainSliceState) => {
        state.showUserMenu = !state.showUserMenu;
    },
    setActiveDepartment :(state:mainSliceState, action: PayloadAction<ActiveDepartment | undefined>) => {
        state.activeDepartment = action.payload;    
    },
    setUsersList :(state:mainSliceState, action: PayloadAction<ActiveUser[]>) => {
        state.usersList = action.payload;
    },
    setUploadingStep :(state:mainSliceState, action: PayloadAction<string>) => {
        state.uploadingStep = action.payload;
    },
    setTasksList: (state:mainSliceState, action: PayloadAction<any>) => {
        state.taskList = action.payload
    }
};
