import { WrapMain } from '../../modules/wrap_main';
import { ErrorComponent } from '../../components/error'
import '../../styles/globalstyles.scss';

export default function Forbidden() {
  return (
    <WrapMain>
      <ErrorComponent type="404" infoPage='Modulo No Encontrado ......' />
    </WrapMain>
  );
}