import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/globalstyles.scss';
import { Providers } from './stateManagement/provider';
import Forbidden from './pages/error/page';
import Home from './pages';
import UploadOptions from './pages/well-whisper/upload/page'
import HistoryOfActivities from './pages/well-whisper/history/page'
import UploadTaskLand from './pages/well-whisper/upload/monitoreo_tierra/page'
import UploadTaskLake from './pages/well-whisper/upload/monitoreo_lago/page'


// Determine the basename dynamically
const isLocalhost = window.location.hostname === 'localhost';
const basename = isLocalhost ? '' : '/';

function App() {

  console.log({ basename });
  return (
    <Providers>
      <Router  basename={basename}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/well-whisper/history" element={<HistoryOfActivities />} />
          <Route path="/well-whisper/upload" element={<UploadOptions />} />
          <Route path="/well-whisper/upload/monitoreo_tierra" element={<UploadTaskLand />} />
          <Route path="/well-whisper/upload/monitoreo_lago" element={<UploadTaskLake />} />
          <Route path="/403" element={<Forbidden />} />
          <Route path="*" element={<Forbidden />} />
        </Routes>
      </Router>
    </Providers>
  );
}

export default App;
