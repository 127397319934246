import {WrapMain} from '../modules/wrap_main'

import '../styles/globalstyles.scss';


export default function Home() {

  return (
      <WrapMain>
          <div/>
      </WrapMain>
  );
}