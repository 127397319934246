import './styles.scss';
import ButtonCircle from '../../components/button_circle';
import { selectMainState, useDispatch, useSelector } from '../../stateManagement/redux';
import { useEffect, useState } from 'react';
import { getTaskList } from '../../stateManagement/redux/slices/main/asyncReducers';
import { useNavigate, useLocation } from 'react-router-dom';

export enum analyzeProcess {
  PENDING = "PENDING",
  ANALYZING = "ANALYZING",
  ANALYZED = "ANALYZED",
  ERROR = "ERROR",
  UNKNOW = "UNKNOW"
}


export interface file {
  id: string;
  name: string;
  url: string;
  process: analyzeProcess | string;
  error: string;
}

export interface TaskListSummary {
  id: string;
  date: string;
  backlog: string;
  user: string;
  summaryFiles: string;
  files: file[];
}


export const HistoryTaskModule = () => {

  const typesOfActivities = ['ECHOMETER', 'LEUTER', 'REVISION']

  // const router = useRouter()

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const mainState = useSelector(selectMainState);

  const [tasks, setTask] = useState<TaskListSummary[]>([])

  const [activeTask, setActiveTask] = useState<TaskListSummary>()

  const [typeOfActivity, setTypeOfActivity] = useState('')

  useEffect(() => {
    dispatch(getTaskList(0))
  }, [])

  useEffect(() => {

    const taskList: TaskListSummary[] = []

    for (const task of mainState?.taskList || []) {

      const date = new Date(task.createdAt);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      const users = []

      for (const user of task.teamMembers) {
        users.push(user.name)
      }

      const taskMapped: TaskListSummary = {
        id: task.id,
        date: `${day}/${month}/${year}`,
        backlog: task.backlog.name,
        user: users.join(', '),
        summaryFiles: `${task.totalProcessed}/${task.totalFiles}`,
        files: task.files.map(file => {
          return {
            id: file.id,
            name: file.detail,
            url: file.url_link,
            process: file?.subtask?.process || analyzeProcess.UNKNOW,
            error: file?.subtask?.errors?.join(',') || ''
          }
        })
      }

      taskList.push(taskMapped)
    }

    setTask(taskList)

  }, [mainState?.taskList])


  const filterTasks = (type: string) => {
      setTypeOfActivity(type)
      dispatch(getTaskList(0,100,type))
  }

  const openTaskDetail = (task: TaskListSummary) => {

    if (activeTask?.id === task.id) {
      setActiveTask(undefined)
    } else {
      setActiveTask(task)
    }

  }


  return (
    <div className="history_task_container">
      {/* Headers */}
      <div className='history_task_container_header'>
        <ButtonCircle icon="/assets/icons/back.svg" iconHeight={50} iconWidth={50} onClick={() => navigate('/well-whisper/upload')} />
        <div className='history_task_container_title'>Historial de Tareas</div>
      </div>
      {/* Filtros */}
      <div className='history_task_container_filter_container'>
        <label htmlFor="file">Tipo Actividad : </label>
        <select id="activity" name="activity" className='' value={typeOfActivity} onChange={(e) => filterTasks(e.target.value)}>
          <option value=''>Seleccione opcion valida</option>
          {typesOfActivities.map((value, index) => <option key={index} value={value}>{value}</option>)}
        </select>
      </div>
      <div className='history_task_container_table_container'>
        {/* Table Header */}
        <div className='history_task_container_table_container_header'>
          <div>Fecha</div>
          <div>Backlog</div>
          <div>Realizado Por</div>
          <div>Archivos</div>
          <div />
        </div>
        {/* Table Body */}
        {tasks.map((task, i) => (
          <div className='history_task_container_table_container_row' key={i}>
            <div className='history_task_container_table_container_row_info_task'>
              <div>{task.date}</div>
              <div>{task.backlog}</div>
              <div>{task.user}</div>
              <div>{task.summaryFiles}</div>
              <div>_______</div>
            </div>
            {task.files.length > 0 && (
              <div className='history_task_container_table_container_row_summary_container'>
                <div className='history_task_container_table_container_file_summary' onClick={() => openTaskDetail(task)}>
                  <span>{task.files.length}</span>
                  <span> Archivos </span>
                  <img src={activeTask?.id === task.id ? "/assets/icons/arrow_up.svg" : "/assets/icons/arrow_down.svg"} width={20} height={20} alt='' />
                </div>
              </div>)}
            {activeTask?.id === task.id && (
              <>
                <div className='history_task_container_sub_table_container'>
                  {/* header */}
                  <div className='history_task_container_sub_table_container_header'>
                    <div>Name</div>
                    <div>Estado</div>
                    <div>Errores</div>
                    <div />
                  </div>
                  {/* body */}
                  {task.files.map((f, i) => (
                    <div className='history_task_container_sub_table_container_row' key={i}>
                      <div>{f.name}</div>
                      <div>{f.process}</div>
                      <div>{f.error}</div>
                    </div>
                  ))}
                </div>
                <div className='history_task_container_table_container_empty_space' />
              </>)}
          </div>))}
      </div>
    </div>
  )
}
