"use client";

import React from 'react'
import { WrapMain } from '../../../modules/wrap_main'
import '../../../styles/globalstyles.scss';
import './styles.scss';
import { title } from 'process';
import { useDispatch } from 'react-redux';
import { mainSlice } from '../../../stateManagement/redux';
import { useNavigate, useLocation } from 'react-router-dom';


interface ProjectsCardProps {
  id:string,
  title: string;
  icon: string;
  description: string;
  path:string;
  alert?: string;
}

const cards: ProjectsCardProps[] = [
  {
    id:'a4b5d644-729a-11ef-b5c1-c9abfae58c0d',
    title: 'Optimizacion Tierra',
    icon: '/assets/icons/leuter_digital.svg',
    description: 'Por este modulo se podrá cargar los archivos de campo tanto excel como Cartas digitales',
    path: '/well-whisper/upload/monitoreo_tierra',
    alert: 'Antes de volver a cargar el archivo, valide el status de la carga anterior'
  },
  {
    id:'cf55299a-729a-11ef-b5c1-c9abfae58c0d',
    title: 'Optimizacion Lago',
    icon: '/assets/icons/monitoreo_lago.svg',
    description: 'Por este modulo se podrá cargar los archivos de campo tanto excel como Cartas digitales',
    path: '/well-whisper/upload/monitoreo_lago',
    alert: 'Antes de volver a cargar el archivo, valide el status de la carga anterior'
  }
]

const UploadCard = (props: ProjectsCardProps) => {

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const onClickMenu = (path: string, activeProject: string) => {
    dispatch(mainSlice.actions.setActiveProjectId(activeProject))
    localStorage.setItem('activeProjectId', activeProject)
    navigate(path)
}


  return (
    <div className='card_container' onClick={()=>onClickMenu(props.path, props.id)}>
      <img src={props.icon} width={120} height={120} alt='' />
      <div className='card_container__title' > {props.title} </div>
      <div className='card_container__description'> {props.description} </div>
      {props.alert && (
        <div className='card_container__alertSection'>
          <img src="/assets/icons/info.svg"  width={20} height={20} alt='' />
          {props.alert}
        </div>
      )}

    </div>
  )
}

const Upload = () => {
  return (
    <div className='upload_container'>
      <div className='upload_container_title_section'>
        <div className='upload_container_title_section__title'>Subir Archivos de actividades al Sistema</div>
        <div className='upload_container_title_section__detail'>Selecionar el tipo de actividad que ha realizado y suba el o los archivos correspondientes</div>
      </div>
      <div className='upload_container_cards_section'>
        {cards.map((card,i) => <UploadCard key={i} icon={card.icon} title={card.title} description={card.description} alert={card.alert} path={card.path} id={card.id} />)}
      </div>
    </div>
  )
}

const index = () => {
  return (
    <WrapMain>
      <Upload />
    </WrapMain>
  )
}

export default index